
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonItem,
  IonButton,
  IonLabel,
  IonList,
  IonListHeader,
  loadingController,
  toastController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  alertCircleOutline,
  addCircleOutline,
  schoolOutline,
  logoNoSmoking,
  languageOutline,
  triangleOutline,
  giftOutline,
  personOutline,
  briefcaseOutline,
} from "ionicons/icons";
import router from "@/router";

import CriteriaEditFormSkeleton from "@/components/criteria/CriteriaEditFormSkeleton.vue";

import { user } from "@/auth";
import { fbDB } from "@/firebase";
import { useI18n } from "vue-i18n";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, min, max, alpha } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";

export default {
  name: "RegCriteria",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonItem,
    IonContent,
    IonLabel,
    IonList,
    IonListHeader,
    CriteriaEditFormSkeleton,
    IonButton,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },

  setup() {
    const { t } = useI18n({ useScope: "global" });
    const loading = ref<boolean>(true);
    const errorDuringLoading = ref<boolean>(false);
    let savingLoader: any;

    defineRule("required", required);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha", alpha);
    configure({
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });

    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("registration.base.saving"),
      });
      await savingLoader.present();
    };

    const openErrorToast = async () => {
      const toast = await toastController.create({
        message: t("registration.base.savingFailed"),
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      name: false,
      year: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };
    const sex = ref<string>("");
    const sexNotSelected = ref<boolean>(false);
    const changeSex = (sexSelected: string) => {
      sexNotSelected.value = false;
      sex.value = sexSelected;
    };

    const loadedYear = ref();
    const loadedName = ref();
    const loadedSex = ref();
    const disabledFlags = ref<{ [key: string]: boolean }>({
      name: false,
      year: false,
      sex: false,
    });
    const addUserToFinderFlag = ref<boolean>(false);
    const skipFlag = ref<boolean>(true);
    
    /**
     * LOAD BASE INFORMATION & CHECK IF THERE ARE ANY
     */
    const loadAccountBaseInfo = async () => {
      loading.value = true;

      if (user.value.displayName) {
        loadedName.value = user.value.displayName;
        disabledFlags.value.name = true;
      }
      try {
        const snap = await fbDB
          .ref(`/users/${user.value.uid}/finder/`)
          .once("value");
        if (snap.val() != null) {
          if (snap.val().year) {
            loadedYear.value = snap.val().year;
            disabledFlags.value.year = true;
          }
          if (snap.val().sex) {
            sex.value = snap.val().sex;
            disabledFlags.value.sex = true;
          }
          if (snap.val().location) {
            addUserToFinderFlag.value = true;
          }
        }
        for (const prop in disabledFlags.value) {
          if (disabledFlags.value[prop] == false) {
            skipFlag.value = false;
          }
        }
      } catch (err) {
        errorDuringLoading.value = true;
      } finally {
        loading.value = false;
      }
    };

    loadAccountBaseInfo();

    /**
     * SAVE BASE ACCOUNT INFORMATION
     */
    const saveAndContinue = async (values: any, actions: any) => {
      if (sex.value == "") {
        sexNotSelected.value = true;
        return;
      }

      await presentLoader();

      try {
        if (skipFlag.value == false) {
          values.name =
            values["name"][0].toUpperCase() +
            values["name"].slice(1).toLowerCase();
          await user.value.updateProfile({ displayName: values.name });
          const updates: { [key: string]: any } = {};
          updates[`users/${user.value.uid}/finder/`] = {
            sex: sex.value,
            year: values.year,
          };
          updates[`users/${user.value.uid}/userInfo/registration`] = {
            base: true,
            criteria: false,
            profile: false,
          };
          await fbDB.ref().update(updates);
        } 
        
        router.push("/registration/criteria");
      } catch (error) {
        openErrorToast();
      } finally {
        savingLoader.dismiss();
      }
    };

    return {
      skipFlag,
      errorDuringLoading,
      disabledFlags,
      loadedName,
      loadedYear,
      loadedSex,
      aggressiveValidation,
      enableAggressiveValidation,
      sex,
      changeSex,
      sexNotSelected,
      required,
      min,
      max,
      alpha,
      user,
      loading,

      saveAndContinue,

      // ICONS
      giftOutline,
      personOutline,
      alertCircleOutline,
      addCircleOutline,
      schoolOutline,
      logoNoSmoking,
      languageOutline,
      triangleOutline,
      briefcaseOutline,
    };
  },
};

<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <ion-page>
        <ion-header class="ion-no-border">
          <ion-toolbar style="text-align: center">
            <img src="/assets/logo.png" style="height: 50px" />
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <div style="max-width: 500px; margin: auto; padding-bottom: 100px">
            <div>
              <div style="text-align: center">
                <h1 style="font-weight: bold; font-size: 40px">
                  {{$t("registration.base.baseInfoHeader")}}
                </h1>
              </div>
              <div>
                <div>
                  <CriteriaEditFormSkeleton v-if="loading" />
                  <div v-else>
                    <div
                      v-if="errorDuringLoading"
                      style="text-align: center; margin: 2rem"
                    >
                      <ion-icon
                        :icon="alertCircleOutline"
                        style="font-size: 64px"
                      ></ion-icon>
                      <h6 style="margin-top: 6px">{{$t("errors.problemOccured")}}</h6>
                    </div>
                    <div v-else>
                      <div style="text-align: center">
                        <h3>{{$t("registration.base.baseInfoSubheader")}}</h3>
                      </div>
                      <div>
                        <v-form @submit="saveAndContinue">
                          <ion-list id="sex" lines="none">
                            <ion-list-header lines="full" mode="ios">
                              <ion-label class="sectionHeader"
                                >{{$t("registration.base.yourName")}}</ion-label
                              >
                            </ion-list-header>
                            <ion-item
                              lines="none"
                              class="fieldItem"
                              :disabled="disabledFlags.name"
                            >
                              <ion-icon
                                :icon="personOutline"
                                slot="start"
                                style="color: #999"
                              ></ion-icon>
                              <v-field
                                v-model="loadedName"
                                name="name"
                                v-slot="{ field }"
                                rules="required|max:50|alpha"
                                :validateOnInput="aggressiveValidation.name"
                                :validateOnBlur="false"
                              >
                                <input
                                  @change="enableAggressiveValidation('name')"
                                  :value="field.value"
                                  name="name"
                                  class="inputField"
                                  v-bind="field"
                                  :placeholder="$t('registration.base.yourNamePlaceholder')"
                                />
                              </v-field>
                            </ion-item>
                            <v-error-message name="name" class="error" />
                          </ion-list>
                          <ion-list id="sex" lines="none">
                            <ion-list-header lines="full" mode="ios">
                              <ion-label class="sectionHeader"
                                >{{$t("registration.base.yourYearOfBirth")}}</ion-label
                              >
                            </ion-list-header>
                            <ion-item
                              lines="none"
                              class="fieldItem"
                              :disabled="disabledFlags.year"
                            >
                              <ion-icon
                                :icon="giftOutline"
                                slot="start"
                                style="color: #999"
                              ></ion-icon>
                              <v-field
                                v-model="loadedYear"
                                name="year"
                                v-slot="{ field }"
                                rules="required"
                                :validateOnInput="aggressiveValidation.year"
                                :validateOnBlur="false"
                              >
                                <select
                                  @change="enableAggressiveValidation('year')"
                                  required
                                  class="inputField"
                                  style="cursor: pointer"
                                  name="year"
                                  v-bind="field"
                                >
                                  <option
                                    value=""
                                    hidden
                                    selected
                                    style="color: red"
                                  >
                                    {{ $t("registration.base.yourYearOfBirthPlaceholder") }}
                                  </option>
                                  <option
                                    :value="2021 - n - 18"
                                    v-for="n in 70"
                                    :key="n"
                                  >
                                    {{ 2021 - n - 18 }}
                                  </option>
                                </select>
                              </v-field>
                            </ion-item>
                            <v-error-message name="year" class="error" />
                          </ion-list>
                          <ion-list id="sex" lines="none">
                            <ion-list-header lines="full" mode="ios">
                              <ion-label class="sectionHeader"
                                >{{$t("registration.base.yourSex")}}</ion-label
                              >
                            </ion-list-header>
                            <div style="margin-top: 5px">
                              <div style="width: 50%; display: inline-block">
                                <ion-button
                                  expand="block"
                                  shape="round"
                                  type="button"
                                  :disabled="disabledFlags.sex"
                                  @click="changeSex('man')"
                                  :fill="sex == 'man' ? 'solid' : 'outline'"
                                  :class="[
                                    sex == 'man'
                                      ? 'selectedSex'
                                      : 'notSelectedSex',
                                  ]"
                                >
                                  {{ $t("titlePage.man") }}
                                </ion-button>
                              </div>
                              <div style="width: 50%; display: inline-block">
                                <ion-button
                                  expand="block"
                                  shape="round"
                                  type="button"
                                  :disabled="disabledFlags.sex"
                                  @click="changeSex('woman')"
                                  :fill="sex == 'woman' ? 'solid' : 'outline'"
                                  class="sex"
                                  :class="[
                                    sex == 'woman'
                                      ? 'selectedSex'
                                      : 'notSelectedSex',
                                  ]"
                                >
                                  {{ $t("titlePage.woman") }}
                                </ion-button>
                              </div>
                            </div>
                            <p
                              v-if="sexNotSelected"
                              style="
                                display: block;
                                margin: 0px 1.5rem;
                                font-weight: light;
                                font-size: smaller;
                                color: rgb(228, 2, 2);
                                padding: 0;
                              "
                            >
                              {{ $t("titlePage.selectSex") }}
                            </p>
                          </ion-list>
                          <p v-if="skipFlag" style="font-size: smaller; text-align:center; margin:0 2rem;">
                            <span style="color:#DA1839">*</span> {{$t("registration.base.alreadyFilledMessage")}}
                            <a href="mailto:support@laska.club"
                              >support@laska.club</a
                            >
                          </p>
                          <div style="padding-top: 45px; padding-bottom: 45px">
                            <ion-button
                              mode="ios"
                              type="submit"
                              expand="block"
                              shape="round"
                              string="true"
                              color="primary"
                              style="margin: 15px 0px; height: 3rem"
                              >{{ $t("buttons.continue") }}</ion-button
                            >
                          </div>
                        </v-form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonItem,
  IonButton,
  IonLabel,
  IonList,
  IonListHeader,
  loadingController,
  toastController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  alertCircleOutline,
  addCircleOutline,
  schoolOutline,
  logoNoSmoking,
  languageOutline,
  triangleOutline,
  giftOutline,
  personOutline,
  briefcaseOutline,
} from "ionicons/icons";
import router from "@/router";

import CriteriaEditFormSkeleton from "@/components/criteria/CriteriaEditFormSkeleton.vue";

import { user } from "@/auth";
import { fbDB } from "@/firebase";
import { useI18n } from "vue-i18n";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, min, max, alpha } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";

export default {
  name: "RegCriteria",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonItem,
    IonContent,
    IonLabel,
    IonList,
    IonListHeader,
    CriteriaEditFormSkeleton,
    IonButton,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },

  setup() {
    const { t } = useI18n({ useScope: "global" });
    const loading = ref<boolean>(true);
    const errorDuringLoading = ref<boolean>(false);
    let savingLoader: any;

    defineRule("required", required);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha", alpha);
    configure({
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });

    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("registration.base.saving"),
      });
      await savingLoader.present();
    };

    const openErrorToast = async () => {
      const toast = await toastController.create({
        message: t("registration.base.savingFailed"),
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      name: false,
      year: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };
    const sex = ref<string>("");
    const sexNotSelected = ref<boolean>(false);
    const changeSex = (sexSelected: string) => {
      sexNotSelected.value = false;
      sex.value = sexSelected;
    };

    const loadedYear = ref();
    const loadedName = ref();
    const loadedSex = ref();
    const disabledFlags = ref<{ [key: string]: boolean }>({
      name: false,
      year: false,
      sex: false,
    });
    const addUserToFinderFlag = ref<boolean>(false);
    const skipFlag = ref<boolean>(true);
    
    /**
     * LOAD BASE INFORMATION & CHECK IF THERE ARE ANY
     */
    const loadAccountBaseInfo = async () => {
      loading.value = true;

      if (user.value.displayName) {
        loadedName.value = user.value.displayName;
        disabledFlags.value.name = true;
      }
      try {
        const snap = await fbDB
          .ref(`/users/${user.value.uid}/finder/`)
          .once("value");
        if (snap.val() != null) {
          if (snap.val().year) {
            loadedYear.value = snap.val().year;
            disabledFlags.value.year = true;
          }
          if (snap.val().sex) {
            sex.value = snap.val().sex;
            disabledFlags.value.sex = true;
          }
          if (snap.val().location) {
            addUserToFinderFlag.value = true;
          }
        }
        for (const prop in disabledFlags.value) {
          if (disabledFlags.value[prop] == false) {
            skipFlag.value = false;
          }
        }
      } catch (err) {
        errorDuringLoading.value = true;
      } finally {
        loading.value = false;
      }
    };

    loadAccountBaseInfo();

    /**
     * SAVE BASE ACCOUNT INFORMATION
     */
    const saveAndContinue = async (values: any, actions: any) => {
      if (sex.value == "") {
        sexNotSelected.value = true;
        return;
      }

      await presentLoader();

      try {
        if (skipFlag.value == false) {
          values.name =
            values["name"][0].toUpperCase() +
            values["name"].slice(1).toLowerCase();
          await user.value.updateProfile({ displayName: values.name });
          const updates: { [key: string]: any } = {};
          updates[`users/${user.value.uid}/finder/`] = {
            sex: sex.value,
            year: values.year,
          };
          updates[`users/${user.value.uid}/userInfo/registration`] = {
            base: true,
            criteria: false,
            profile: false,
          };
          await fbDB.ref().update(updates);
        } 
        
        router.push("/registration/criteria");
      } catch (error) {
        openErrorToast();
      } finally {
        savingLoader.dismiss();
      }
    };

    return {
      skipFlag,
      errorDuringLoading,
      disabledFlags,
      loadedName,
      loadedYear,
      loadedSex,
      aggressiveValidation,
      enableAggressiveValidation,
      sex,
      changeSex,
      sexNotSelected,
      required,
      min,
      max,
      alpha,
      user,
      loading,

      saveAndContinue,

      // ICONS
      giftOutline,
      personOutline,
      alertCircleOutline,
      addCircleOutline,
      schoolOutline,
      logoNoSmoking,
      languageOutline,
      triangleOutline,
      briefcaseOutline,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

.error {
  display: block;
  margin: 5px 1.5rem;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}

ion-item.fieldItem {
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 5px;
  height: 3rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

ion-item.fieldItem:hover {
  border-color: #808080;
}

.inputField {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}

select:invalid {
  color: #808080;
}

option {
  color: black;
}

ion-list {
  border-radius: 25px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

ion-list p {
  padding: 0.5rem;
}

ion-list-header {
  padding: 0.5rem;
}

ion-chip {
  margin-left: 0;
}

ion-list-header ion-label.sectionHeader {
  margin: 0.5rem 0 0 0;
}

ion-button.selectedSex {
  height: 3rem;
  --background: #808080 !important;
  --background-hover: #ccc !important;
  --background-focused: #ccc !important;
  --background-activated: #ccc !important;
  color: white;
  text-transform: capitalize;
}

ion-button.notSelectedSex {
  height: 3rem;
  color: #808080;
  --border-color: #ccc;
  --background-hover: #ccc !important;
  --background-focused: #ccc !important;
  --background-activated: #ccc !important;
  --border-width: 1px;
  text-transform: capitalize;
}
</style>